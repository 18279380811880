import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import Link from './Link'
import LogoEU from '../../assets/images/logos/eu.svg'

class Gdpr extends React.Component {
  // setCookie = (name, value, days = 30, path = '/') => {
  //   if (typeof window !== 'undefined') {
  //     const expires = new Date(Date.now() + days * 864e5).toUTCString()
  //     document.cookie =
  //       name +
  //       '=' +
  //       encodeURIComponent(value) +
  //       '; expires=' +
  //       expires +
  //       '; path=' +
  //       path
  //   }
  // }

  setSessionStorage = (name, value) => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem(name, value);
    }
  }

  acceptAnalytics = () => {
    this.setSessionStorage('zavenGdpr', 'accepted')
    this.divRef.classList.remove('gdprShow')
    this.divRef.classList.add('gdprHide')
    this.divRef.classList.remove('position-bottom')
  }

  render() {
    return (
      <div
        ref={r => (this.divRef = r)}
        id="cookie"
        className="alert gdpr-alert alert-dismissable position-bottom py-4 gdprShow"
      >
        <div className="container">
          <div className="gdpr-body">
            <div className="mr-4 mb-2 text-xs-left">
              <strong>
              We gebruiken tracking tools op deze website om de gebruikerservaring en communicatie te optimaliseren.
              </strong>
              <br />
              Ga naar ons 
              <Link
                to="/legal#privacy-policy"
                className="gdpr-alert-btn"
              >
                {' '}
                privacybeleid&nbsp;
              </Link>
              voor meer informatie over hoe we deze gegevens gebruiken
              
            </div>
            <div>
              <button
                id="gdpr-accept-btn"
                className="gdpr-alert-btn btn btn-outline-primary"
                style={{ width: 161 }}
                onClick={this.acceptAnalytics}
              >
                <FontAwesomeIcon icon={faCheck} /> Accepteren
              </button>
              <Link to="/eu-project/">
                <img src={LogoEU} className='eu-logo' alt='eu-logo' />
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Gdpr
